<template>
  <v-row justify="center">
    <v-col cols="12" class="pa-0" md="5">
      <v-container fill-height fluid class="bg-white">
        <div style="width: 60%" class="mx-auto">
          <router-view></router-view>
        </div>
      </v-container>
    </v-col>
    <v-col cols="7" class="pa-0 d-none d-md-block pa-0">
      <v-img height="100%" :src="loginImg"></v-img>
    </v-col>
  </v-row>
</template>

<script>
import loginImg from "@/assets/login-page-cover-photo.jpg";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loginImg,
    };
  },
};
</script>

<style></style>
